import React from 'react';

export default function Home({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.043"
      height="26.114"
      viewBox="0 0 28.043 26.114"
      fill={color || '#fff'}
    >
      <defs />
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M27.573,29.949,23.323,25.3V19.934a1.614,1.614,0,1,0-3.215,0v1.853l-3.165-3.46a3.973,3.973,0,0,0-5.846,0L.471,29.949a1.876,1.876,0,0,0,0,2.485,1.512,1.512,0,0,0,2.274,0L13.371,20.816a.891.891,0,0,1,1.3,0L25.3,32.435a1.514,1.514,0,0,0,2.274,0A1.876,1.876,0,0,0,27.573,29.949Z"
            transform="translate(-0.001 -17.047)"
          />
          <path
            className="a"
            d="M78.939,128a.7.7,0,0,0-1.077,0L68.85,138.218a.927.927,0,0,0-.223.611v7.452a3,3,0,0,0,2.792,3.166h4.462v-7.836H80.92v7.836h4.462a3,3,0,0,0,2.792-3.166v-7.452a.924.924,0,0,0-.223-.611Z"
            transform="translate(-64.38 -123.334)"
          />
        </g>
      </g>
    </svg>
  );
}
