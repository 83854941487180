import React from 'react';

export default function Express() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.674"
      height="21.998"
      viewBox="0 0 32.674 21.998"
      fill="#fff"
    >
      <defs />
      <g transform="translate(-6.556)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M14.536,149.857a3.277,3.277,0,1,0,3.274,3.279A3.275,3.275,0,0,0,14.536,149.857Zm0,4.917a1.637,1.637,0,1,1,1.637-1.637A1.639,1.639,0,0,1,14.536,154.773Zm24.694-7.339v3.5a1.579,1.579,0,0,1-1.579,1.579h-1.69a4.333,4.333,0,0,0-8.575,0H18.822a4.33,4.33,0,0,0-8.57,0H8.136a1.579,1.579,0,0,1-1.579-1.579v-3.5Zm-7.555,2.422a3.277,3.277,0,1,0,3.274,3.279A3.28,3.28,0,0,0,31.675,149.857Zm0,4.917a1.637,1.637,0,1,1,1.637-1.637A1.639,1.639,0,0,1,31.675,154.773Zm6.933-10.86L32.4,137.776a2.11,2.11,0,0,0-1.479-.611H27.791v-1.174a1.577,1.577,0,0,0-1.579-1.579H8.135a1.58,1.58,0,0,0-1.579,1.579v10.57H39.229v-1.153A2.116,2.116,0,0,0,38.608,143.913Zm-2.706.205H29.9a.247.247,0,0,1-.247-.247v-4.638a.247.247,0,0,1,.247-.247h1.142a.267.267,0,0,1,.174.068l4.859,4.638A.247.247,0,0,1,35.9,144.119Z"
            transform="translate(0 -134.412)"
          />
        </g>
      </g>
    </svg>
  );
}
