import React from 'react';

export default function Product() {
  return (
    <svg width="24.093"
      height="24.093"
      viewBox="0 0 30 30"
      fill="#fff" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.84,1.48a5.18,5.18,0,0,0-1.78.42L2.88,5.23c-1.23.44-1.26.69-1.31,1.94L1.14,20.84c-.07,1.61,0,1.94,1.36,2.55l10.85,4.75a3.33,3.33,0,0,0,2.64.19L26.62,25c1.28-.43,1.46-.57,1.51-2.16l.45-14.61a1.51,1.51,0,0,0-1.1-1.73L15.75,1.92a4.94,4.94,0,0,0-1.91-.44Zm.05,1.46c1.38,0,2.66.49,5.13,1.45,5.58,2.17,7.7,3.14,4.07,4.55-4.32,1.68-7.48,2.64-10.06,1.51L6.54,7.61c-2-.88-2.61-1.6,1-2.92,3.25-1.18,4.83-1.75,6.3-1.75ZM16.5,5.49c-2.23,0-3.23,1.24-3.32,2.28a1.64,1.64,0,0,0,.73,1.53,3.68,3.68,0,0,0,2.86.35A19.36,19.36,0,0,0,21.3,8.31a3.58,3.58,0,0,0,1-.63c.18-.18.2-.29.2-.35s0-.16-.19-.32a3.17,3.17,0,0,0-.89-.53,14.84,14.84,0,0,0-3.84-.92,7.78,7.78,0,0,0-1-.07ZM3.38,7.74A12.29,12.29,0,0,1,7.11,9.07c8.23,3.66,8.21,3.84,7.65,11.72-.45,6.41-.51,6.41-7.5,3.46-4.28-1.8-5.07-2.39-5-5.2L2.6,9.54c0-1.17.09-1.78.78-1.8ZM25.69,9.55c1.7,0,1.7,1.64,1.56,6.15-.24,7.89-.53,8.19-7,10.14-4.12,1.24-4.76,1.5-4.61-.7l.64-9.59c.19-2.8.44-3,5.54-4.9a14.08,14.08,0,0,1,3.9-1.1Zm-1.2,2.38h0a1,1,0,0,0-.83.49,2.48,2.48,0,0,0-.34,1.63,6.08,6.08,0,0,1-.88,3.32,4.08,4.08,0,0,1-2.79,2.27,2.69,2.69,0,0,0-2.55,2.46,2.07,2.07,0,0,0,.45,1.44,2.14,2.14,0,0,0,1.59.7A5.69,5.69,0,0,0,24.44,22c1.19-1.66,1.78-4.31,1.51-8-.11-1.52-.86-2-1.46-2Zm-14.38.51H10a.5.5,0,0,0-.33.28,2.38,2.38,0,0,0-.26,1v0a2.62,2.62,0,0,1-1.05,2,4.15,4.15,0,0,1-1.81.53,6.51,6.51,0,0,0-1.65.33A2.05,2.05,0,0,0,3.77,17.8a2.81,2.81,0,0,0-.18,2.25A3.94,3.94,0,0,0,5,21.73a27.27,27.27,0,0,0,5.2,2.44,3.73,3.73,0,0,0,1.59.36,1.25,1.25,0,0,0,.9-.38,5.38,5.38,0,0,0,.88-3.35c.11-3.72-1.1-6.44-2.26-7.65a2,2,0,0,0-1.23-.71Zm10.76.4H20.7c-2.07.11-2.83.75-3.07,1.4a2.66,2.66,0,0,0,.63,2.32c.67.86,1.21.92,1.78.72a3.3,3.3,0,0,0,1.6-1.57,3.26,3.26,0,0,0,.42-2.12.88.88,0,0,0-.38-.56,1.44,1.44,0,0,0-.81-.2Z" />
    </svg>
  );
}
