exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-entrega-agendada-js": () => import("./../../../src/pages/entrega-agendada.js" /* webpackChunkName: "component---src-pages-entrega-agendada-js" */),
  "component---src-pages-entrega-express-js": () => import("./../../../src/pages/entrega-express.js" /* webpackChunkName: "component---src-pages-entrega-express-js" */),
  "component---src-pages-entrega-js": () => import("./../../../src/pages/entrega.js" /* webpackChunkName: "component---src-pages-entrega-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-layouts-desktop-index-js": () => import("./../../../src/pages/_layouts/desktop/index.js" /* webpackChunkName: "component---src-pages-layouts-desktop-index-js" */),
  "component---src-pages-layouts-mobile-index-js": () => import("./../../../src/pages/_layouts/mobile/index.js" /* webpackChunkName: "component---src-pages-layouts-mobile-index-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */)
}

